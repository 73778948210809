import { LYRIC_FIELDS as lf } from "../commons/fields";
import { LocalStorage } from "../utils/localStorage";
import api from "./client-api";

export default class LyricsService {
  async getLyrics(payload) {
    const store = LocalStorage.get(LocalStorage.KEY_LYRICS);
    if (store) return store;

    try {
      const { data } = await api.post("/getLyrics", payload);
      LocalStorage.save(LocalStorage.KEY_LYRICS, data);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getCifra(payload) {
    const key = payload[lf.PATH];
    const store = LocalStorage.get(LocalStorage.KEY_CIFRAS);
    if (store && store[key]) return store[key];

    try {
      const { data } = await api.post("/getCifra", payload);
      LocalStorage.save(LocalStorage.KEY_CIFRAS, {
        ...store,
        [key]: data,
      });
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getLyricVideo(payload) {
    //const store = LocalStorage.get(LocalStorage.KEY_VIDEOS);
    //if (store) return store;
    try {
      const { data } = await api.post("/getLyricVideo", payload);
      return data;
    } catch (error) {
      throw error;
    }
  }
}
