import { Hidden, Paper } from "@material-ui/core";
import {
  FavoriteBorder,
  ImportContacts,
  LibraryBooks,
  MusicVideo,
  ViewList,
} from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import versosJson from "../../assets/json/versos.json";
import { BIBLIA_PAGE, LETRAS_PAGE } from "../../commons/constants";
import { VERSOS_FIELDS as vf } from "../../commons/fields";
import { useStyles } from "./styles";

export default function HinarioMobile() {
  const classes = useStyles();
  const history = useHistory();

  const index = Math.round(Math.random() * (versosJson.length - 1));
  const randomVerso = versosJson[index];

  return (
    <div className={classes.content}>
      <div className={classes.screen}>
        <Hidden xsDown>
          <div className={classes.header}>
            <span>Hinario Cristão</span>
            <FavoriteBorder />
          </div>
        </Hidden>
        <div className={classes.verso}>
          <h5>
            {randomVerso[vf.LIVRO][vf.BOOK]} {randomVerso[vf.CAPITULO]}:
            {randomVerso[vf.VERSICULO]}
          </h5>
          <p>{randomVerso[vf.TEXTO]}</p>
        </div>
        <div className={classes.menu}>
          <Paper
            elevation={4}
            onClick={() => {
              history.push(BIBLIA_PAGE);
            }}
          >
            <ImportContacts />
            <span>Biblia Sagrada</span>
          </Paper>
          <Paper
            elevation={4}
            onClick={() => {
              history.push(LETRAS_PAGE, {
                index: 2,
              });
            }}
          >
            <MusicVideo />
            <span>Hinário</span>
          </Paper>
          <Paper
            elevation={4}
            onClick={() => {
              history.push(LETRAS_PAGE, {
                index: 0,
              });
            }}
          >
            <LibraryBooks />
            <span>Cantor Cristão</span>
          </Paper>
          <Paper
            elevation={4}
            onClick={() => {
              history.push(LETRAS_PAGE, {
                index: 1,
              });
            }}
          >
            <ViewList />
            <span>Harpa Cristã</span>
          </Paper>
        </div>
      </div>
    </div>
  );
}
