import TagManager from "react-gtm-module";

const GTM = {
  init() {
    TagManager.initialize({
      gtmId: "GTM-MK7CXTR",
    });
  },

  pageView() {
    const url = window.location.pathname;

    TagManager.dataLayer({
      dataLayer: {
        event: "pageview",
        page: { url },
      },
    });
  },

  event(eventInfo) {
    if (!eventInfo) return;

    TagManager.dataLayer({
      dataLayer: {
        ...eventInfo,
      },
    });
  },
};

export default GTM;
