import { alpha, makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.appColors.whiteColor,
    justifyContent: "center",
    height: 60,
    //marginBottom: 10,
  },

  toolbar: {
    maxWidth: 1200,
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(0, 2),
    color: theme.appColors.blackColor,
  },

  title: {
    alignItems: "center",
    cursor: "pointer",

    "& h6": {
      fontWeight: 600,
      fontSize: 20,
    },

    "& img": {
      marginRight: 10,
      width: 40,
    },
  },

  search: {
    width: "35%",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.appColors.grayColor, 0.2),
    padding: "0px 10px",
    display: "flex",
    alignItems: "center",
    color: theme.appColors.grayColor,

    "&:hover": {
      backgroundColor: alpha(theme.appColors.grayColor, 0.5),
    },

    "& svg": {
      marginRight: 10,
    },
  },

  menu: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
  },

  link: {
    fontSize: "0.805rem",
    textDecoration: "none",
    minWidth: 100,
    textAlign: "center",
    border: "1px solid #f8f8f8",
    padding: "5px 0",
    borderRadius: 10,
    marginLeft: 10,
    color: theme.appColors.blackColor,

    "&:hover": {
      cursor: "pointer",
      fontWeight: 600,
      backgroundColor: theme.palette.primary.dark,
      color: theme.appColors.whiteColor,
    },
  },

  active: {
    fontWeight: 600,
    backgroundColor: theme.palette.primary.main,
    color: theme.appColors.whiteColor,
  },

  item: {
    display: "flex",
    whiteSpace: "normal",

    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.appColors.whiteColor,
    },

    "& svg": {
      marginRight: 10,
    },

    "& p": {
      margin: 0,
    },

    [theme.breakpoints.down("xs")]: {
      marginRight: 0,
    },
  },

  listItem: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    whiteSpace: "normal",
    padding: 15,
    backgroundColor: "#FFF",
    fontSize: 14,

    "& svg": {
      fontSize: 20,
    },
  },

  divider: {
    fontWeight: 600,
    padding: "20px 15px",
  },

  drawer: {
    backgroundColor: "#f3f3f3",
  },
}));
