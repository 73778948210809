import {
  appelStore,
  googlePlay,
  hinarioQrCode,
  bibliaQrCode,
} from "../assets/images";

const HOME_PAGE = "/inicio";

const LETRAS_PAGE = "/letras";

const BIBLIA_PAGE = "/biblia";

const PESQUISA_PAGE = "/pesquisa";

const TERMOS_PAGE = "/termos";

const APPS = [
  {
    name: "Hinário Cristão",
    links: [
      {
        url: "https://play.google.com/store/apps/details?id=es.esy.alexandrehenrique.hinariobatista",
        img: googlePlay,
      },
      {
        url: "https://apps.apple.com/app/hin%C3%A1rio-crist%C3%A3o-e-biblia/id1620777834?platform=iphone",
        img: appelStore,
      },
    ],
    description: (
      <>
        <h1>Hinário Cristão e Bíblia</h1>
        <img src={hinarioQrCode} alt="hinario" height={80} />
        <br />
        Tenha mais de 2000 mil Hinos Evangélicos + versões da{" "}
        <a href={BIBLIA_PAGE}>Biblia </a> completa totalmente offline, tudo isso
        disponivel para vocês diretamento no seu celular! Baixe o Hinário
        Cristão e acompanhe tambem as <a href={LETRAS_PAGE}>Letras e Cifras</a>{" "}
        dos seus hinos favoritos.
      </>
    ),
    gradient: [
      "rgba(37,92,106,1) 0%",
      "rgba(0,194,203,1) 52%",
      "rgba(0,212,255,1) 100%",
    ],
  },
  {
    name: "Biblias Offline",
    links: [
      {
        url: "https://play.google.com/store/apps/details?id=br.com.bibliasoffline",
        img: googlePlay,
      },
      {
        url: "https://apps.apple.com/us/app/biblias-offline/id1625514479",
        img: appelStore,
      },
    ],
    description: (
      <>
        <h1>Biblias Offline</h1>
        <img src={bibliaQrCode} alt="biblia" height={80} />
        <br />
        Instale agora o aplicativo Bíblia Offlines e tenha acesso a todo o seu
        conteúdo 100% gratuito e sem precisar estar conectado a internet. Uso
        100% offline. Disponive download da Nova Versão Internacional, Almeida
        Corrigida, Almeida Atualizada.
      </>
    ),
    gradient: [
      "rgba(84, 28, 12, 1) 0%",
      "rgba(185, 86, 70, 1) 52%",
      "rgba(232, 124, 107,1) 100%",
    ],
  },
];

export {
  APPS,
  BIBLIA_PAGE,
  HOME_PAGE,
  LETRAS_PAGE,
  PESQUISA_PAGE,
  TERMOS_PAGE,
};
