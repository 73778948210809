import { Box, Tabs } from "@material-ui/core";
import React, { useState } from "react";
import { CustomTab } from "./styles";

export default function TabPanel({ tabs = [], selectIndex = 0, onChangeTab }) {
  const [tab, setTab] = useState(selectIndex);

  function handleChangeTab(e, value) {
    setTab(value);
    if (onChangeTab) onChangeTab(value);
  }

  return (
    <div style={{ width: "100%" }}>
      <Tabs value={tab} onChange={handleChangeTab}>
        {tabs.map((tab, i) => (
          <CustomTab
            value={i}
            key={`${i + 1}-tab`}
            icon={tab.icon}
            label={tab.label}
            iconPosition="start"
          />
        ))}
      </Tabs>
      <Box paddingX={2} paddingY={4}>
        {tabs[selectIndex]?.content}
      </Box>
    </div>
  );
}
