import { alpha, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: 10,
    justifyContent: "center",
    padding: theme.spacing(2, 1),
    backgroundColor: theme.appColors.whiteColor,
  },

  maxWidth: {
    maxWidth: 1200,
  },

  title: {
    fontSize: 24,
    fontWeight: 600,
    letterSpacing: "1.25px",
    textTransform: "uppercase",
    margin: "auto 0",

    "& span": {
      fontSize: 15,
    },
  },

  voice: {
    width: 160,
  },

  bible: {
    fontSize: 22,
    fontWeight: 600,
    letterSpacing: "1.25px",
    margin: "auto 0",
    textAlign: "center",
  },

  accordion: {
    width: "100%",
  },

  book: {
    padding: theme.spacing(1, 2),
    marginBottom: 10,
    marginRight: 10,

    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },

  active: {
    color: theme.appColors.whiteColor,
    backgroundColor: theme.palette.primary.main,
    fontWeight: 600,
  },

  dot: {
    width: 30,
    height: 30,
    fontSize: 13,
    cursor: "pointer",

    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    borderRadius: "50%",
    border: `1px solid ${theme.palette.primary.main}`,
    marginRight: 5,
    marginBottom: 5,
  },

  versos: {
    minxHeight: "80vh",
    margin: theme.spacing(1, 0, 3, 0),

    "& div": {
      padding: "10px 5px",
    },

    "& div:nth-child(2n)": {
      backgroundColor: "#f3f3f3",
    },
  },

  select: {
    "&.MuiInput-underline:before": {
      borderBottom: "none",
    },
  },

  text: {
    cursor: "pointer",
    "&:hover": {
      fontWeight: 600,
    },

    "& svg": {
      marginLeft: 10,
    },
  },

  inputSearch: {
    display: "flex",
    alignItems: "center",
    padding: "7px 10px",
    margin: "10px 0",
    borderRadius: 12,
    backgroundColor: alpha(theme.appColors.grayColor, 0.2),
  },

  btnSearch: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    "& button": {
      borderTopRightRadius: 12,
      borderBottomRightRadius: 12,
      borderBottomLeftRadius: 0,
      borderTopLeftRadius: 0,
      color: theme.appColors.whiteColor,
      backgroundColor: theme.palette.primary.main,
      height: 46,
    },

    "& button:hover": {
      backgroundColor: theme.palette.primary.dark,
    },

    [theme.breakpoints.down("sm")]: {
      "& button": {
        borderRadius: 12,
        width: "100%",
      },
    },
  },
}));
