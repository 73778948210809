import { Grid, IconButton } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core";
import {
  FirstPage,
  LastPage,
  NavigateBefore,
  NavigateNext,
} from "@material-ui/icons";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  dots: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 30,
    height: 30,
    borderRadius: "50%",
    fontSize: 14,
    marginRight: "1%",
    cursor: "pointer",
    backgroundColor: theme.appColors.ligthGray,
  },
  active: {
    fontWeight: 600,
    backgroundColor: theme.palette.primary.main,
    color: theme.appColors.whiteColor,
  },
}));

export default function Paginate({
  count = 1,
  current = 1,
  maxCount = 10,
  onClickDot,
  onClickNext,
  onClickPrev,
  onClickFirst,
  onClickLast,
}) {
  const styles = useStyles();

  const dots = Array.from(Array(count));
  const initDot = current >= maxCount ? current - maxCount + 1 : 0;
  const finalDot =
    current >= maxCount && count >= maxCount ? current + 1 : maxCount;

  return (
    <Grid item xs={12} container justifyContent="center" alignItems="center">
      <IconButton disabled={count === 1} onClick={onClickFirst}>
        <FirstPage />
      </IconButton>
      <IconButton disabled={count === 1} onClick={onClickPrev}>
        <NavigateBefore />
      </IconButton>

      <div style={{ width: 20 }} />

      {dots.slice(initDot, finalDot).map((_, i) => {
        const dot = i + 1 + initDot;
        let css = styles.dots;

        if (count > 1 && dot === current) {
          css = clsx(styles.dots, styles.active);
        }

        return (
          <div
            key={`dt-${i + 1}`}
            className={css}
            onClick={() => {
              if (onClickDot) return onClickDot(dot);
            }}
          >
            {dot}
          </div>
        );
      })}

      <div style={{ width: 20 }} />

      <IconButton disabled={count === 1} onClick={onClickNext}>
        <NavigateNext />
      </IconButton>

      <IconButton disabled={count === 1} onClick={onClickLast}>
        <LastPage />
      </IconButton>
    </Grid>
  );
}
