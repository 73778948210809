import { Tab, withStyles } from "@material-ui/core";

export const CustomTab = withStyles((theme) => ({
  root: {
    minWidth: 120,
    minHeight: "auto",
    padding: "10px 0",
    textTransform: "none",
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: 10,

    "& .MuiTab-wrapper": {
      flexDirection: "row",
      justifyContent: "flex-start",
      paddingRight: 10,
    },

    "& .MuiTab-wrapper > svg": {
      marginRight: 15,
    },
  },
}))((props) => <Tab disableRipple {...props} />);
