import { CircularProgress, Grid } from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import { useStyles } from "./styles";

export default function ListView({
  items = [],
  onClickItem,
  selectIndex,
  loading,
}) {
  const classes = useStyles();

  if (loading) {
    return (
      <div className={classes.loading}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <Grid container>
      {items.map((item, i) => {
        let css = classes.item;

        if (selectIndex === i) {
          css = clsx(css, classes.select);
        }

        return (
          <Grid
            item
            xs={12}
            className={css}
            key={`itm-${i + 1}`}
            onClick={() => {
              if (onClickItem) {
                onClickItem(item, i);
              }
            }}
          >
            <Grid item xs className={classes.leading}>
              {item.icon}
              <span>{item.title}</span>
            </Grid>
            {!!item?.subItems?.length && (
              <Grid item xs container justifyContent="flex-end">
                {item?.subItems}
              </Grid>
            )}
          </Grid>
        );
      })}
    </Grid>
  );
}
