import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  content: {
    position: "relative",
    width: 300,
    height: 600,

    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: "100%",
    },
  },

  screen: {
    position: "absolute",
    top: 0,
    left: 12,
    width: "92%",
    height: "96%",
    borderRadius: 50,
    color: theme.appColors.whiteColor,
    background: `linear-gradient(180deg, 
      rgba(37,92,106,1) 0%,
      rgba(37,92,106,1) 45%,
      rgba(255,255,255,1) 45%,
      rgba(255,255,255,1) 100%
    )`,

    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: "100vh",
      borderRadius: 0,
      position: "initial",

      background: `linear-gradient(180deg, 
        rgba(37,92,106,1) 0%,
        rgba(37,92,106,1) 27%,
        rgba(255,255,255,1) 27%,
        rgba(255,255,255,1) 100%
      )`,
    },
  },

  header: {
    width: "80%",
    fontSize: "0.915rem",
    fontWeight: 600,
    padding: theme.spacing(7, 0, 0, 3),

    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",

    "& svg": {
      fontSize: 20,
    },

    [theme.breakpoints.down("xs")]: {
      width: "90%",
      padding: theme.spacing(3, 2),
    },
  },

  verso: {
    padding: theme.spacing(0, 3),
    minHeight: 90,
    marginBottom: 25,

    "& p": {
      fontSize: "0.70rem",
    },

    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(0, 2),
      minHeight: "20%",
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      textAlign: "left",

      "& p": {
        fontSize: "0.85rem",
      },
    },
  },

  menu: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridGap: 10,
    padding: "0 22px",

    "& div": {
      color: theme.palette.primary.dark,
      backgroundColor: theme.appColors.whiteColor,
      borderRadius: 10,
      height: 80,

      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
    },

    "& svg": {
      fontSize: 32,
      marginBottom: 10,
    },

    "& span": {
      fontSize: "0.65rem",
      fontWeight: 600,
    },

    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(0, 2.5),

      "& div": {
        height: 110,
      },

      "& svg": {
        fontSize: 40,
      },

      "& span": {
        fontSize: "0.80rem",
      },
    },
  },

  text: {
    color: theme.appColors.whiteColor,
    justifyContent: "center",

    "& img": {
      height: 45,
      borderRadius: 12,
      marginRight: 15,
    },

    [theme.breakpoints.down("sm")]: {
      marginTop: 30,
    },
  },

  images: {
    position: "absolute",
    bottom: "20%",
    left: 0,
    width: "100%",
    display: "flex",
    justifyContent: "center",

    "& img": {
      height: 45,
      width: "100%",
      backgroundColor: "#0000",
      borderRadius: 12,
    },

    "& a:last-child": {
      marginLeft: 15,
    },
  },
}));
