import { alpha, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: 10,
    justifyContent: "center",
    padding: theme.spacing(2, 1),
    backgroundColor: theme.appColors.whiteColor,
  },

  maxWidth: {
    minHeight: "90vh",
    maxWidth: 1200,
    alignContent: "flex-start",
  },

  title: {
    fontWeight: 600,
    letterSpacing: "1.25px",
    marginTop: 15,
  },

  chip: {
    color: theme.appColors.whiteColor,
    letterSpacing: "1.25px",
    fontSize: "0.95rem",
    textTransform: "uppercase",
    fontWeight: 600,
  },

  card: {
    padding: theme.spacing(1, 0),
    marginBottom: 10,
    marginRight: 10,
    minHeight: 150,

    "& h6": {
      width: "100%",
      fontWeight: 600,
      margin: "0 20px",
    },

    "& p": {
      margin: "10px 20px",
    },

    "& span": {
      fontSize: 13,
    },
  },

  btnNext: {
    color: theme.palette.primary.dark,
    fontWeight: 600,
  },

  inputSearch: {
    display: "flex",
    alignItems: "center",
    padding: "7px 10px",
    margin: "10px 0",
    borderRadius: 12,
    backgroundColor: alpha(theme.appColors.grayColor, 0.2),
  },

  btnSearch: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    "& button": {
      borderTopRightRadius: 12,
      borderBottomRightRadius: 12,
      borderBottomLeftRadius: 0,
      borderTopLeftRadius: 0,
      color: theme.appColors.whiteColor,
      backgroundColor: theme.palette.primary.main,
      height: 46,
    },

    "& button:hover": {
      backgroundColor: theme.palette.primary.dark,
    },

    [theme.breakpoints.down("sm")]: {
      "& button": {
        borderRadius: 12,
        width: "100%",
      },
    },
  },

  loading: {
    width: "100%",
    height: "50vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    flexDirection: "column",
  },

  paginate: {
    margin: theme.spacing(4, 0),
  },

  warning: {
    justifyContent: "center",

    [theme.breakpoints.up("sm")]: {
      justifyContent: "space-between",
    },
  },
}));
