import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: null,
  message: null,
  loading: false,
};

export const sourceSlice = createSlice({
  name: "sources",
  initialState,
  reducers: {
    setSources: (state, { payload }) => {
      state.data = payload;
      state.loading = false;
    },
    setLoading: (state, { payload }) => {
      state.loading = payload;
    },
    setMessage: (state, { payload }) => {
      state.message = payload;
    },
  },
});

const { actions, reducer } = sourceSlice;

export const { setSources, setLoading, setMessage } = actions;

export default reducer;
