import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Dialog,
  DialogContent,
  FormHelperText,
  Grid,
  Hidden,
  IconButton,
  InputBase,
  MenuItem,
  Select,
  Slide,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import {
  Cancel,
  Edit,
  ExpandMore,
  GraphicEq,
  Pause,
  RecordVoiceOver,
  Search,
  VolumeUp,
} from "@material-ui/icons";
import clsx from "clsx";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import booksJson from "../../assets/json/books.json";
import { PESQUISA_PAGE } from "../../commons/constants";
import { BIBLE_FIELDS as bf } from "../../commons/fields";
import { TagBooks } from "../../components/TagBooks";
import BibleService from "../../services/bible";
import {
  addBookBible,
  setLoading,
  setMessage,
  setSearchBible,
} from "../../store/slices/bible";
import synth, { Speecher } from "../../utils/speech";
import { useStyles } from "./styles";
import { BannerAds } from "../../components/Banner";

const service = new BibleService();

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function BiblePage({ history }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  const { bibles, sources, loading } = useSelector((state) => ({
    loading: state.bible.loading,
    bibles: state.bible.data,
    sources: state.sources.data,
  }));

  const state = history.location?.state;

  const [source, setSource] = useState("bible-aa");
  const [book, setBook] = useState(booksJson[0]);
  const [chapter, setChapter] = useState(0);

  const [search, setSearch] = useState("");
  const [error, setError] = useState(null);
  const [expanded, setExpanded] = useState(true);
  const [dialog, setDialog] = useState({ open: false });

  const [voice, setVoice] = useState(null);
  const [voices, setVoices] = useState([]);
  const [reading, setReading] = useState(null); // { index: 0, hide: true }

  const bible = bibles.find((s) => s.bible === source);
  const books = bible?.books ?? [];
  const currentBook = books?.find((b) => b?.[bf.ABREV] === book?.[bf.ABREV]);
  const chaptersDots = currentBook?.[bf.CHAPTERS]?.length || 0;
  const versos = currentBook?.[bf.CHAPTERS][chapter][bf.VERSOS] || [];

  const handleCloseDialog = () => {
    setDialog({ open: false });
  };

  const handleChangeSearch = ({ target }) => {
    setSearch(target.value);
  };

  const readerAllVersos = (start = 0) => {
    let text = "";

    if (start === 0) {
      text = `${currentBook?.[bf.BOOK]}, `;
      text += `capitulo ${chapter + 1}, `;
    }

    text += `versiculo ${start + 1}. ${versos[start]}`;
    Speecher.speak(text, voice, {
      onstart: () => {
        setReading({ index: start, hide: true });
      },
      onend: () => {
        const next = start + 1;
        if (next < versos.length) {
          readerAllVersos(next);
        } else {
          setReading(null);
        }
      },
    });
  };

  const setSearchVersos = () => {
    if (search.length < 3) {
      setError("Informe o minimo de 3 caracteres.");
      return;
    }

    const result = [];

    currentBook?.[bf.CHAPTERS].forEach((c, i) => {
      const filterVersos = [];

      c[bf.VERSOS].forEach((f, j) => {
        if (f.toLowerCase().includes(search.toLowerCase())) {
          filterVersos.push({
            number: j + 1,
            verso: f,
          });
        }
      });

      if (filterVersos.length > 0) {
        result.push({
          chapter: i + 1,
          versos: filterVersos,
        });
      }
    });

    dispatch(
      setSearchBible({
        text: search,
        data: [
          {
            [bf.ABREV]: currentBook[bf.ABREV],
            [bf.BOOK]: currentBook[bf.BOOK],
            [bf.CHAPTERS]: result,
          },
        ],
      })
    );

    history.push(PESQUISA_PAGE);
  };

  const showCloudTagBooks = () => {
    setDialog({
      open: true,
      maxWidth: "md",
      content: (
        <TagBooks
          selectedBook={book}
          onClikBook={(b) => {
            handleCloseDialog();
            setBook(b);
          }}
        />
      ),
    });
  };

  const renderButtonPlayPause = (verso, i) => {
    if (!voices.length || reading?.hide) return;

    if (reading?.index === i) {
      return (
        <IconButton
          style={{ padding: 0 }}
          onClick={() => {
            Speecher.pause();
            setReading(null);
          }}
        >
          <Pause />
        </IconButton>
      );
    }

    return (
      <IconButton
        style={{ padding: 0 }}
        onClick={() => {
          let text = `${currentBook?.[bf.BOOK]}, `;
          text += `capitulo ${chapter + 1}, `;
          text += `versiculo ${i + 1}. ${verso}`;

          Speecher.speak(text, voice, {
            onstart: () => setReading({ index: i }),
            onend: () => setReading(null),
          });
        }}
      >
        <VolumeUp />
      </IconButton>
    );
  };

  const getVoices = useCallback(() => {
    const v = Speecher.voices();
    setVoices(v);
    setVoice(v?.[0]);
  }, []);

  const getBible = useCallback(() => {
    if (!currentBook) {
      dispatch(setLoading(true));
      service
        .getBible({
          source: source,
          abbrev: book?.[bf.ABREV],
        })
        .then(({ result }) => {
          dispatch(
            addBookBible({
              bible: source,
              book: result,
            })
          );
        })
        .catch((err) => {
          dispatch(setMessage(err));
        });
    }
  }, [dispatch, book, source, currentBook]);

  useEffect(() => {
    getBible();
  }, [getBible]);

  useEffect(() => {
    getVoices();
    if (synth.onvoiceschanged !== undefined) {
      synth.onvoiceschanged = getVoices;
    }
  }, [getVoices]);

  useEffect(() => {
    if (state?.select) {
      setBook({
        [bf.ABREV]: state.select[bf.ABREV],
        [bf.BOOK]: state.select[bf.BOOK],
      });
      setChapter(state.select[bf.CHAPTER] - 1);
      setReading({ index: state.select[bf.VERSO] - 1 });
    }

    if (state?.source) {
      setSource(state?.source?.source);
    }
  }, [state]);

  useEffect(() => {
    if (isMobile) {
      setExpanded(false);
    }
  }, [isMobile]);

  return (
    <>
      <Grid container className={classes.container}>
        <Grid item xs={12} spacing={2} container className={classes.maxWidth}>
          <Grid item xs={12} container justifyContent="center">
            <Select
              value={source}
              className={classes.select}
              onChange={({ target }) => {
                setSource(target.value);
              }}
            >
              {sources?.bibles?.map((b) => (
                <MenuItem key={b.source} value={b.source}>
                  <Typography variant="body1">{b.label}</Typography>
                </MenuItem>
              ))}
            </Select>
          </Grid>

          <Grid item xs={12} container justifyContent="center">
            <Grid item xs={12} md={7} container>
              <Grid item xs={12} md={10} className={classes.inputSearch}>
                <Search color="primary" />
                <InputBase
                  fullWidth
                  type="search"
                  placeholder={`Pesquisar em ${currentBook?.[bf.BOOK]}`}
                  onChange={handleChangeSearch}
                />
              </Grid>
              <Grid item xs={12} md={2} className={classes.btnSearch}>
                <IconButton onClick={setSearchVersos}>
                  <Search />
                  <Typography>Pesquisar</Typography>
                </IconButton>
              </Grid>
              <FormHelperText error>{error}</FormHelperText>
            </Grid>
          </Grid>

          {!loading && (
            <>
              <Grid item xs={12} container>
                <Accordion
                  className={classes.accordion}
                  expanded={expanded}
                  onChange={() => {
                    setExpanded(!expanded);
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={classes.title}>
                      {currentBook?.[bf.BOOK]}
                      <span> ({currentBook?.[bf.ABREV]}), </span>
                      {chapter + 1}
                    </Typography>
                    <IconButton onClick={showCloudTagBooks}>
                      <Edit />
                    </IconButton>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid item xs={12} container>
                      {Array.from(Array(chaptersDots)).map((_, i) => {
                        const css = i === chapter ? classes.active : null;
                        return (
                          <div
                            key={`dot-${i}`}
                            className={clsx(classes.dot, css)}
                            onClick={() => {
                              setChapter(i);
                            }}
                          >
                            {i + 1}
                          </div>
                        );
                      })}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Grid>

              {!!voices.length && (
                <Grid
                  item
                  xs={12}
                  container
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Button
                    startIcon={reading?.hide ? <Cancel /> : <GraphicEq />}
                    style={{ textTransform: "none" }}
                    onClick={() => {
                      setReading(null);

                      if (reading?.hide) {
                        Speecher.pause();
                      } else {
                        readerAllVersos();
                      }
                    }}
                  >
                    {reading?.hide ? "Cancelar" : "Ler tudo"}
                  </Button>
                  <Box display="flex" alignItems="center">
                    <RecordVoiceOver style={{ marginRight: 10 }} />
                    <Select
                      value={voice?.name ?? ""}
                      className={classes.select}
                      onChange={({ target }) => {
                        setVoice(Speecher.voiceByName(target.value));
                      }}
                    >
                      {voices.map((v) => (
                        <MenuItem key={v.name} value={v.name}>
                          <Typography variant="body1">{v.name}</Typography>
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                </Grid>
              )}

              <Grid item xs={12} container className={classes.versos}>
                {versos.map((item, i) => {
                  return (
                    <Grid
                      item
                      xs={12}
                      container
                      alignItems="center"
                      key={`vrs-${i}`}
                    >
                      <Typography className={classes.text}>
                        <b>{i + 1}</b> -{" "}
                        <span
                          style={{
                            backgroundColor:
                              reading?.index === i ? "yellow" : null,
                          }}
                        >
                          {item}
                        </span>
                        {renderButtonPlayPause(item, i)}
                      </Typography>
                    </Grid>
                  );
                })}
              </Grid>
            </>
          )}

          <Grid item xs={12} container justifyContent="center">
            <BannerAds />
          </Grid>

          <Hidden xsDown>
            <Grid item xs={12} container>
              <TagBooks
                selectedBook={book}
                onClikBook={(b) => {
                  setBook(b);
                }}
              />
            </Grid>
          </Hidden>
        </Grid>
      </Grid>

      <Dialog
        fullWidth
        scroll="paper"
        open={dialog.open}
        maxWidth={dialog.maxWidth}
        TransitionComponent={Transition}
        onClose={handleCloseDialog}
      >
        <DialogContent>
          <Grid container>{dialog.content}</Grid>
        </DialogContent>
      </Dialog>
    </>
  );
}
