export const LYRIC_FIELDS = {
  TITLE: "titulo",
  LYRIC: "letra",
  CIFRAS: "cifras",
  PATH: "path",
};

export const SOURCES_FIELDS = {
  LYRICS: "lyrics",
  BIBLE: "bible",
  LABEL: "label",
  SOURCE: "source",
  CIFRAS: "cifras",
};

export const VERSOS_FIELDS = {
  TEXTO: "texto",
  LIVRO: "livro",
  CAPITULO: "capitulo",
  VERSICULO: "versiculo",
  BOOK: "book",
  ABREV: "abbrev",
  CHAPTER: "chapters",
};

export const BIBLE_FIELDS = {
  ABREV: "abbrev",
  BOOK: "book",
  CHAPTERS: "chapters",
  CHAPTER: "chapter",
  VERSOS: "versos",
  VERSO: "verso",
  TEXT: "text",
  NUMBER: "number",
  SOURCE: "source",
  SEARCH: "search",
};
