import { BIBLE_FIELDS as bf } from "../commons/fields";
import { LocalStorage } from "../utils/localStorage";
import api from "./client-api";

export default class BibleService {
  async getBible(payload) {
    const key = payload[bf.ABREV] + "-" + payload[bf.SOURCE];
    const store = LocalStorage.get(LocalStorage.KEY_BIBLE);

    if (store && store[key]) return store[key];

    try {
      const { data } = await api.post("/getBible", payload);
      LocalStorage.save(LocalStorage.KEY_BIBLE, {
        ...store,
        [key]: data,
      });
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getSources() {
    const store = LocalStorage.get(LocalStorage.KEY_SOURCES);
    if (store) return store;

    try {
      const { data } = await api.post("/getSources");
      LocalStorage.save(LocalStorage.KEY_SOURCES, data);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getVersos() {
    const store = LocalStorage.get(LocalStorage.KEY_VERSOS);
    if (store) return store;

    try {
      const { data } = await api.post("/getVersos");
      LocalStorage.save(LocalStorage.KEY_VERSOS, data);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async searchVersos(payload) {
    const store = LocalStorage.get(LocalStorage.KEY_SEARCHS);
    const key =
      payload[bf.SEARCH]?.split(" ").join("-") + "-" + payload[bf.SOURCE];

    if (store && store[key]) return store[key];

    try {
      const { data } = await api.post("/searchVersos", payload);
      if (data?.result?.length) {
        LocalStorage.save(LocalStorage.KEY_SEARCHS, {
          ...store,
          [key]: data,
        });
      }
      return data;
    } catch (error) {
      throw error;
    }
  }
}
