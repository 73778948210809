const synth = window.speechSynthesis;

export class Speecher {
  static speak(text, voice, events) {
    const utterance = new SpeechSynthesisUtterance();
    utterance.voice = voice;
    utterance.text = text;

    if (events) {
      const { onstart, onend } = events;
      utterance.onstart = onstart;
      utterance.onend = onend;
    }

    utterance.onerror = console.log;
    synth.speak(utterance);
  }

  static pause() {
    synth.cancel();
  }

  static voices(lang) {
    if (!synth) return [];

    const keyLang = lang ?? navigator.language;
    const voices = synth.getVoices();
    const filter = voices.filter((v) => v.lang === keyLang);

    if (!filter.length && !lang) return this.voices("pt");

    return filter;
  }

  static voiceByName(name) {
    const voices = synth.getVoices();
    return voices.find((v) => v.name === name);
  }
}

export default synth;
