import axios from "axios";

const baseURL = process.env.REACT_APP_URL_API || "http://localhost:4000/api";

const api = axios.create({
  baseURL,
  timeout: 30000,
});

export default api;
