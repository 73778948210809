import { Grid, Hidden, IconButton, Typography } from "@material-ui/core";
import { ArrowBackIos, ArrowForwardIos } from "@material-ui/icons";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { BibleIcon } from "../../assets/icons";
import { APPS, BIBLIA_PAGE } from "../../commons/constants";
import { BIBLE_FIELDS as bf } from "../../commons/fields";
import { BannerAds } from "../../components/Banner";
import HinarioMobile from "../../components/HinarioMobile";
import GTM from "../../configs/gtm";
import BibleService from "../../services/bible";
import { setVersosData } from "../../store/slices/dominio";
import { useStyles } from "./styles";

const service = new BibleService();

export default function HomePage() {
  const dispatch = useDispatch();

  const { versos } = useSelector((state) => state.dominio);
  const [index, setIndex] = useState(0);

  const selectApp = APPS[index];
  const gradient = selectApp.gradient?.toString();
  const backgroundImage = selectApp.backgroundImage;

  const classes = useStyles({ gradient, backgroundImage });

  const getRandomVersos = () => {
    return Array.from(versos).sort(() => Math.random() - 0.5);
  };

  const getVersos = useCallback(() => {
    if (versos?.length === 0) {
      service
        .getVersos()
        .then(({ result }) => {
          dispatch(setVersosData(result));
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [dispatch, versos.length]);

  useEffect(() => {
    getVersos();
  }, [getVersos]);

  useEffect(() => {
    GTM.pageView();
  }, []);

  return (
    <>
      <Hidden smUp>
        <HinarioMobile />
      </Hidden>
      <Hidden xsDown>
        <Grid item xs container className={classes.container}>
          <Grid
            item
            xs={12}
            container
            justifyContent="center"
            alignContent="center"
            style={{ maxWidth: 650 }}
          >
            <Grid item xs={12} container className={classes.text}>
              <IconButton
                className={classes.arrowLeft}
                onClick={() => {
                  const i = index > 0 ? index - 1 : APPS.length - 1;
                  setIndex(i);
                }}
              >
                <ArrowBackIos />
              </IconButton>
              <div className={classes.bg}>{selectApp.description}</div>
              <IconButton
                className={classes.arrowRight}
                onClick={() => {
                  const i = index < APPS.length - 1 ? index + 1 : 0;
                  setIndex(i);
                }}
              >
                <ArrowForwardIos />
              </IconButton>
              {selectApp.links.map((item, i) => (
                <a
                  key={`lnk-${i + 1}`}
                  href={item.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={item.img} alt="loja" className={classes.lojas} />
                </a>
              ))}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} container justifyContent="center">
          <Grid item container className={classes.versos}>
            <Grid item xs={12} container className={classes.subtitle}>
              <img src={BibleIcon} alt="bible" height={50} />
              <Typography variant="h5">Versiculos do dia</Typography>
            </Grid>
            <Grid item xs={12} container spacing={4}>
              {getRandomVersos()
                .slice(0, 6)
                .map((verso, i) => (
                  <Grid
                    item
                    xs={4}
                    container
                    key={`vrs-${i + 1}`}
                    alignContent="space-between"
                    className={classes.verso}
                  >
                    <Grid item xs={12}>
                      <Typography>{verso.texto}</Typography>
                    </Grid>
                    <Grid item container justifyContent="flex-end">
                      <Link
                        to={{
                          pathname: BIBLIA_PAGE,
                          state: {
                            select: {
                              [bf.ABREV]: verso.livro.abbrev,
                              [bf.BOOK]: verso.livro.book,
                              [bf.CHAPTER]: verso.capitulo,
                              [bf.VERSO]: verso.versiculo,
                            },
                          },
                        }}
                      >
                        {verso.livro.book}, {verso.capitulo}:{verso.versiculo}
                      </Link>
                    </Grid>
                  </Grid>
                ))}
            </Grid>
          </Grid>
        </Grid>
      </Hidden>
      <Grid item xs={12} container justifyContent="center">
        <BannerAds />
      </Grid>
    </>
  );
}
