import React, { useEffect, useState } from "react";
import {
  Chip,
  CircularProgress,
  FormHelperText,
  Grid,
  IconButton,
  InputBase,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@material-ui/core";
import { useStyles } from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { BIBLE_FIELDS as bf } from "../../commons/fields";
import { NavigateNext, Search, Share } from "@material-ui/icons";
import {
  setSearchBible,
  setLoading,
  setMessage,
} from "../../store/slices/bible";
import { BIBLIA_PAGE } from "../../commons/constants";
import BibleService from "../../services/bible";
import Paginate from "../../components/Paginate";
import GTM from "../../configs/gtm";
import { BannerAds } from "../../components/Banner";

var service = new BibleService();
var CARDS_PAGE = 10;

export default function SearchPage({ history }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [page, setPage] = useState(1);
  const [text, setText] = useState(null);
  const [error, setError] = useState(null);
  const [source, setSource] = useState("bible-aa");

  const { sources, search, loading } = useSelector((state) => ({
    sources: state.sources.data,
    search: state.bible.search,
    loading: state.bible.loading,
  }));

  const handleChangeSearch = ({ target }) => {
    setText(target.value);
  };

  const setSearchVersos = () => {
    if (!text || text?.length < 3) {
      setError("Informe o minimo de 3 caracteres.");
      return;
    }

    dispatch(setLoading(true));
    service
      .searchVersos({
        source,
        search: text,
      })
      .then(({ result }) => {
        dispatch(
          setSearchBible({
            text,
            data: result,
          })
        );
      })
      .catch((err) => {
        dispatch(setMessage(err));
      });
  };

  const createCardVersos = () => {
    const cards = [];

    search?.data?.forEach((s) => {
      s[bf.CHAPTERS].forEach((c) => {
        c[bf.VERSOS].forEach((v) => {
          cards.push({
            text: v[bf.VERSO],
            title: `${s[bf.BOOK]}, ${s[bf.ABREV]} - ${c[bf.CHAPTER]}:${
              v[bf.NUMBER]
            }`,
            select: {
              [bf.ABREV]: s[bf.ABREV],
              [bf.BOOK]: s[bf.BOOK],
              [bf.CHAPTER]: c[bf.CHAPTER],
              [bf.VERSO]: v[bf.NUMBER],
            },
          });
        });
      });
    });

    return cards;
  };

  const removeSearch = () => {
    dispatch(setSearchBible(null));
  };

  useEffect(() => {
    GTM.pageView();
  }, []);

  const cardVersos = createCardVersos();

  const initGap = (page - 1) * CARDS_PAGE;
  const finalGap = initGap + CARDS_PAGE;
  const sliceCards = cardVersos.slice(initGap, finalGap);
  const count =
    cardVersos.length > CARDS_PAGE
      ? Math.ceil(cardVersos.length / CARDS_PAGE)
      : 1;

  return (
    <>
      <Grid container className={classes.container}>
        <Grid item xs={12} spacing={2} container className={classes.maxWidth}>
          <Grid item xs={12} container justifyContent="center">
            <Select
              value={source}
              onChange={({ target }) => {
                setSource(target.value);
              }}
            >
              {sources?.bibles?.map((b) => (
                <MenuItem key={b.source} value={b.source}>
                  <Typography variant="body1">{b.label}</Typography>
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12} container justifyContent="center">
            <Grid item xs={12} md={7} container>
              <Grid item xs={12} md={10} className={classes.inputSearch}>
                <Search color="primary" />
                <InputBase
                  fullWidth
                  type="search"
                  placeholder="Digite uma palavra ou versiculo"
                  onChange={handleChangeSearch}
                />
              </Grid>
              <Grid item xs={12} md={2} className={classes.btnSearch}>
                <IconButton onClick={setSearchVersos}>
                  <Search />
                  <Typography>Pesquisar</Typography>
                </IconButton>
              </Grid>
              <Grid item xs={12} container className={classes.warning}>
                <FormHelperText error>{error}</FormHelperText>
                <Typography align="right" variant="caption">
                  para pequisar um versiculo especifíco <br /> utilize Livro Cap
                  Vers, ex: joão 3 16
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {search && (
              <Chip
                color="primary"
                className={classes.chip}
                label={search?.[bf.TEXT]}
                onDelete={removeSearch}
              />
            )}
            <Typography className={classes.title}>
              {cardVersos.length > 0 &&
                !loading &&
                `Encontrados: ${cardVersos.length} versiculos`}
            </Typography>
          </Grid>
          <Grid item xs={12} container>
            {loading ? (
              <div className={classes.loading}>
                <CircularProgress />
                <Typography>Pesquisando Versiculos</Typography>
              </div>
            ) : sliceCards.length > 0 ? (
              <>
                <Grid item xs={12} container>
                  {sliceCards.map((verso) => (
                    <Grid
                      item
                      md={5}
                      xs={12}
                      container
                      elevation={2}
                      component={Paper}
                      key={verso.title}
                      className={classes.card}
                    >
                      <Typography variant="h6">{verso.title}</Typography>

                      <Typography>{verso.text}</Typography>

                      <Grid
                        item
                        xs={12}
                        container
                        justifyContent="space-between"
                      >
                        {navigator.share ? (
                          <IconButton
                            className={classes.btnNext}
                            onClick={() => {
                              navigator
                                .share({
                                  title: verso.title,
                                  text: verso.text,
                                  url: window.location.href,
                                })
                                .then((res) => {
                                  console.log(res);
                                });
                            }}
                          >
                            <Share style={{ fontSize: 18 }} />
                            <span>Compartilhar</span>
                          </IconButton>
                        ) : (
                          <div></div>
                        )}
                        <IconButton
                          className={classes.btnNext}
                          onClick={() => {
                            history.push(BIBLIA_PAGE, {
                              select: verso.select,
                              source: { source },
                            });
                          }}
                        >
                          <span>Ver na biblia</span>
                          <NavigateNext />
                        </IconButton>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
                <Grid item xs={12} container className={classes.paginate}>
                  <Paginate
                    count={count}
                    current={page}
                    onClickDot={(value) => setPage(value)}
                    onClickPrev={() => {
                      setPage(count >= page ? page - 1 : count);
                    }}
                    onClickNext={() => {
                      setPage(page < count ? page + 1 : 1);
                    }}
                    onClickFirst={() => {
                      setPage(1);
                    }}
                    onClickLast={() => {
                      setPage(count);
                    }}
                  />
                </Grid>
              </>
            ) : null}
          </Grid>
        </Grid>
        <Grid item xs={12} container justifyContent="center">
          <BannerAds />
        </Grid>
      </Grid>
    </>
  );
}
