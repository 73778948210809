import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.appColors.primaryColorDark,
    justifyContent: "center",
    alignItems: "center",
    minHeight: 300,
    padding: theme.spacing(3, 0),
    color: "white",

    position: "relative",
  },

  menu: {
    justifyContent: "center",
    "& a": {
      display: "flex",
      color: "white",
      textDecoration: "none",
      //width: "100%",
      fontSize: 16,
      cursor: "pointer",
      alignItems: "center",
      marginRight: 30,
    },

    "& svg": {
      marginRight: 10,
    },
  },

  images: {
    justifyContent: "center",
    margin: theme.spacing(6, 0),

    "& img": {
      height: 45,
      width: "100%",
      backgroundColor: "#0000",
      borderRadius: 12,
    },

    "& a:last-child": {
      marginLeft: 15,
    },
  },
}));
