import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  videos: [],
  message: null,
  loading: false,
};

export const lyricsSlice = createSlice({
  name: "lyrics",
  initialState,
  reducers: {
    addLyricsData: (state, { payload }) => {
      console.log(payload);
      state.data = [...payload];
      state.loading = false;
    },
    addLyricVideo: (state, { payload }) => {
      state.videos.push(payload);
    },
    setLyricCifra: (state, { payload }) => {
      const { source, select, cifra } = payload;
      const tempIndex = state.data.findIndex((d) => d.source === source);
      if (tempIndex > -1) {
        const copy = state.data[tempIndex];
        const cifras = copy.lyrics[select].cifras;
        const index = cifras.findIndex((l) => l.path === cifra.path);
        cifras[index] = cifra;
        copy.lyrics[select].cifras = cifras;
        state.data[tempIndex] = copy;
      }
    },
    setLoading: (state, { payload }) => {
      state.loading = payload;
    },
    setMessage: (state, { payload }) => {
      state.message = payload;
      state.loading = false;
    },
  },
});

const { actions, reducer } = lyricsSlice;

export const {
  addLyricsData,
  addLyricVideo,
  setLyricCifra,
  setLoading,
  setMessage,
} = actions;

export default reducer;
