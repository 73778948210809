import { alpha, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: 10,
    justifyContent: "center",
    padding: theme.spacing(2, 1),
    backgroundColor: theme.appColors.whiteColor,
  },

  maxWidth: {
    maxWidth: 1200,
  },

  text: {
    padding: "10px 40px",

    [theme.breakpoints.down("sm")]: {
      marginTop: 40,
    },

    "& p": {
      marginTop: 10,
    },

    "& h5": {
      marginBottom: 20,
      fontWeight: 600,
    },
  },

  overflow: {
    height: "auto",

    [theme.breakpoints.up("md")]: {
      height: "90vh",
      overflow: "scroll",
    },

    "&::-webkit-scrollbar": { width: "0px" },
  },

  menu: {
    marginBottom: 10,
    marginTop: 20,
    justifyContent: "space-between",

    [theme.breakpoints.up("sm")]: {
      justifyContent: "flex-start",
    },

    "& svg": {
      marginRight: 10,
    },
  },

  menuList: {
    width: 400,
    height: 400,
    overflowY: "scroll",

    backgroundColor: theme.appColors.whiteColor,
  },

  button: {
    maxWidth: "33%",
    borderRadius: 0,

    [theme.breakpoints.down(500)]: {
      "& .MuiIconButton-label": {
        flexDirection: "column",
        whiteSpace: "nowrap",
      },

      "& svg": {
        marginBottom: 10,
      },
    },
  },

  active: {
    borderBottom: `2px solid ${theme.palette.primary.main}`,
  },

  select: {
    backgroundColor: theme.palette.primary.main,
    color: theme.appColors.whiteColor,
    fontWeight: 600,

    "& svg": {
      color: theme.appColors.whiteColor,
    },
  },

  inputSearch: {
    display: "flex",
    alignItems: "center",
    padding: "7px 10px",
    margin: "10px 0",
    borderRadius: 12,
    backgroundColor: alpha(theme.appColors.grayColor, 0.2),
  },

  btnSearch: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    "& button": {
      borderTopRightRadius: 12,
      borderBottomRightRadius: 12,
      borderBottomLeftRadius: 0,
      borderTopLeftRadius: 0,
      color: theme.appColors.whiteColor,
      backgroundColor: theme.palette.primary.main,
      height: 46,
    },

    "& button:hover": {
      backgroundColor: theme.palette.primary.dark,
    },

    [theme.breakpoints.down("sm")]: {
      "& button": {
        borderRadius: 12,
        width: "100%",
      },
    },
  },

  card: {
    //padding: theme.spacing(2, 1, 3, 2),
    //backgroundColor: "#F8EFBA",
    fontSize: 17,
    marginBottom: 15,
    textAlign: "center",
    textTransform: "capitalize",
  },

  scroll: {
    marginTop: 20,

    width: "100%",
    display: "flex",
    flexWrap: "nowrap",
    overflowX: "scroll",
  },
}));
