import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container: ({ gradient }) => ({
    justifyContent: "center",
    backgroundColor: theme.appColors.whiteColor,
    padding: theme.spacing(5, 0),
    background: `linear-gradient(90deg, ${gradient})`,

    [theme.breakpoints.down("xs")]: {
      padding: "0px",
    },
  }),

  content: {
    position: "relative",
    width: 300,
    height: 600,

    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: "100%",
    },
  },

  header: {
    width: "80%",
    fontSize: "0.915rem",
    fontWeight: 600,
    padding: theme.spacing(7, 0, 0, 3),

    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",

    "& svg": {
      fontSize: 20,
    },

    [theme.breakpoints.down("xs")]: {
      width: "90%",
      padding: theme.spacing(3, 2),
    },
  },

  verso: {
    padding: theme.spacing(0, 3),

    "& p": {
      fontSize: "1.03em",
      textAlign: "center",
      marginBottom: 10,
      color: "gray",
    },

    "& a": {
      textDecoration: "none",
      color: theme.palette.primary.dark,
    },
  },

  versos: {
    padding: theme.spacing(4, 2),
    maxWidth: 1200,
    minHeight: 600,
    alignContent: "flex-start",
  },

  subtitle: {
    padding: theme.spacing(2, 2),
    marginBottom: 20,
    justifyContent: "center",
    alignItems: "center",
    //borderBottom: "1px solid lightgrey",
  },

  menu: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridGap: 10,
    padding: "0 22px",

    "& div": {
      color: theme.palette.primary.dark,
      backgroundColor: theme.appColors.whiteColor,
      borderRadius: 10,
      height: 80,

      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
    },

    "& svg": {
      fontSize: 32,
      marginBottom: 10,
    },

    "& span": {
      fontSize: "0.65rem",
      fontWeight: 600,
    },

    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(0, 2.5),

      "& div": {
        height: 110,
      },

      "& svg": {
        fontSize: 40,
      },

      "& span": {
        fontSize: "0.80rem",
      },
    },
  },

  text: {
    position: "relative",
    color: theme.appColors.whiteColor,
    justifyContent: "center",

    [theme.breakpoints.down("sm")]: {
      marginTop: 30,
    },
  },

  lojas: {
    height: 35,
    borderRadius: 12,
    marginRight: 15,
  },

  arrowLeft: {
    position: "absolute",
    left: -5,
    top: 120,
    color: "white",
  },

  arrowRight: {
    position: "absolute",
    right: -10,
    top: 120,
    color: "white",
  },

  bg: {
    width: "85%",
    marginBottom: 40,
    backgroundColor: "rgba(0, 0, 0, .2)",
    padding: theme.spacing(1, 2, 6, 2),
    borderRadius: 16,
    fontSize: "0.95rem",
    letterSpacing: "0.35px",
    lineHeight: "24px",
    textAlign: "center",

    "& a": {
      fontWeight: 600,
      color: theme.appColors.whiteColor,
      textDecoration: "none",
    },

    "& h1": {
      textAlign: "center",
    },

    "& img": {
      margin: "20px 0",
    },
  },

  social: {
    marginTop: 30,
    color: theme.appColors.whiteColor,
    justifyContent: "center",

    "& svg": {
      color: theme.appColors.whiteColor,
      fontSize: 30,
      marginRight: 10,
    },
  },

  images: {
    position: "absolute",
    bottom: "20%",
    left: 0,
    width: "100%",
    display: "flex",
    justifyContent: "center",

    "& img": {
      height: 45,
      width: "100%",
      backgroundColor: "#0000",
      borderRadius: 12,
    },

    "& a:last-child": {
      marginLeft: 15,
    },
  },
  shortcut: {
    padding: "10px 5px",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: theme.appColors.primaryColorDark,

    "& svg": {
      color: theme.appColors.whiteColor,
      marginRight: 10,
    },

    "& h6": {
      color: theme.appColors.whiteColor,
      fontSize: 15,
      fontWeight: 600,
      margin: 0,
    },
  },
}));
