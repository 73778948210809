import { useEffect, useRef } from "react";

export const banner320x300 = {
  key: "29531a98a16c89d1325b47cb9e812deb",
  format: "iframe",
  height: 250,
  width: 300,
  params: {},
};

export const banner320x50 = {
  key: "cd10e8de28a9b5678788c65524c6510c",
  format: "iframe",
  height: 50,
  width: 320,
  params: {},
};

export function BannerAds({ opts = banner320x50 }) {
  const banner = useRef();

  useEffect(() => {
    if (banner.current && !banner.current.firstChild) {
      const conf = document.createElement("script");
      const script = document.createElement("script");

      script.type = "text/javascript";
      script.src = `//www.topcreativeformat.com/${opts.key}/invoke.js`;

      conf.innerHTML = `atOptions = ${JSON.stringify(opts)}`;

      banner.current.append(conf);
      banner.current.append(script);
    }
  }, [banner, opts]);

  return (
    <div
      ref={banner}
      style={{
        minWidth: opts.width,
        minHeight: opts.height,
      }}
    ></div>
  );
}
