import {
  Box,
  FormControl,
  Grid,
  Hidden,
  IconButton,
  InputBase,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Select,
  Typography,
} from "@material-ui/core";
import {
  LibraryBooks,
  MusicNote,
  MusicVideo,
  Search,
  ViewList,
} from "@material-ui/icons";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LYRIC_FIELDS as lf, SOURCES_FIELDS as sf } from "../../commons/fields";
import {
  addLyricVideo,
  addLyricsData,
  setLoading,
  setLyricCifra,
  setMessage,
} from "../../store/slices/lyrics";
import { capitalizePharse } from "../../utils/formatters";
import { useStyles } from "./styles";

import clsx from "clsx";
import { useRef } from "react";
import ReactPlayer from "react-player/lazy";
import { BannerAds, banner320x300 } from "../../components/Banner";
import ListView from "../../components/ListView";
import TabPanel from "../../components/TabPanel";
import GTM from "../../configs/gtm";
import LyricsService from "../../services/lyrics";
import { GuittarIcon, LyricIcon } from "../../assets/icons";

const service = new LyricsService();
const iconData = [<LibraryBooks />, <ViewList />, <MusicVideo />];

export default function LyricsPage({ history }) {
  const classes = useStyles();
  const anchorRef = useRef(null);
  const dispatch = useDispatch();

  const initialTab = history.location?.state?.index || 0;

  const { lyrics, videos, loading } = useSelector((state) => ({
    lyrics: state.lyrics.data,
    videos: state.lyrics.videos,
    loading: state.lyrics.loading,
  }));

  const [tab, setTab] = useState(initialTab);
  const [tabCifra, setTabCifra] = useState(0);
  const [select, setSelect] = useState(0);
  const [search, setSearch] = useState("");

  const tabNames = lyrics?.map((l) => l[sf.LABEL]) || [];
  const dataLyrics = lyrics?.[tab]?.[sf.LYRICS] || [];

  const lyric = dataLyrics?.[select];
  const cifras = lyric?.[lf.CIFRAS] || [];
  const paragraphs = lyric?.[lf.LYRIC].replace(/\r|\t|/gi, "").split("\n\n");
  const videosSearch = paragraphs?.length ? paragraphs[1] : null;
  const videosMap = videos.find((v) => v.search === videosSearch);
  const source = lyrics?.[tab]?.[sf.SOURCE];

  const createDataSearch = () => {
    if (search.length < 2) return [];

    const mergeList = [];

    lyrics.forEach((l, i) => {
      const objects = l[sf.LYRICS].map((o, j) => ({
        tab: i,
        select: j,
        [sf.LABEL]: l[sf.LABEL],
        [lf.TITLE]: o[lf.TITLE],
      }));

      mergeList.push(...objects);
    });

    return mergeList.filter((f) =>
      f[lf.TITLE]?.toLowerCase().includes(search?.toLowerCase())
    );
  };

  const handleChangeSearch = (e) => {
    setSearch(e.target.value);
  };

  const getCifraByPath = (tabIndex) => {
    const cifra = cifras[tabIndex];
    if (!cifra?.content) {
      service
        .getCifra({ path: cifra.path })
        .then(({ result }) => {
          dispatch(
            setLyricCifra({
              source,
              select,
              cifra: { ...cifra, ...result },
            })
          );
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const getLyricVideo = useCallback(() => {
    if (videosSearch && !videosMap) {
      service
        .getLyricVideo({ search: videosSearch })
        .then(({ result }) => {
          if (result?.length) {
            dispatch(
              addLyricVideo({
                search: videosSearch,
                videos: result,
              })
            );
          }
        })
        .catch((err) => {
          dispatch(setMessage(err));
        });
    }
  }, [dispatch, videosMap, videosSearch]);

  const getLyrics = useCallback(() => {
    if (dataLyrics.length === 0) {
      dispatch(setLoading(true));
      service
        .getLyrics({ source: "*" })
        .then(({ result }) => {
          dispatch(addLyricsData(result));
        })
        .catch((err) => {
          dispatch(setMessage(err));
        });
    }
  }, [dispatch, dataLyrics.length]);

  useEffect(() => {
    getLyrics();
    getLyricVideo();
  }, [getLyrics, getLyricVideo]);

  useEffect(() => {
    GTM.pageView();
  }, []);

  const dataSearch = createDataSearch();

  return (
    <Grid container className={classes.container}>
      <Grid item container className={classes.maxWidth}>
        <Grid item xs={12} container justifyContent="center">
          <Grid item xs={12} md={7} className={classes.inputSearch}>
            <Search color="primary" />
            <InputBase
              fullWidth
              type="search"
              ref={anchorRef}
              placeholder="Pesquisar Hino"
              onChange={handleChangeSearch}
            />
          </Grid>
          <Grid item xs={12} md={7} container>
            <Popper
              disablePortal
              role={undefined}
              open={dataSearch.length > 0}
              anchorEl={anchorRef.current}
              style={{ zIndex: 1 }}
            >
              <Paper elevation={5}>
                <MenuList className={classes.menuList}>
                  {dataSearch.map((item, i) => (
                    <MenuItem
                      key={`mnl-${i}`}
                      onClick={() => {
                        setTab(item.tab);
                        setSelect(item.select);
                        setSearch("");
                      }}
                    >
                      {item[sf.LABEL]} - {item[lf.TITLE]}
                    </MenuItem>
                  ))}
                </MenuList>
              </Paper>
            </Popper>
          </Grid>
        </Grid>

        <Grid item xs={12} container spacing={2}>
          <Grid item xs={12} md={6}>
            <Grid item xs={12} className={classes.menu}>
              {tabNames.map((label, i) => {
                let css = classes.button;
                if (tab === i) {
                  css = clsx(classes.button, classes.active);
                }
                return (
                  <IconButton
                    key={`btn-${i + 1}`}
                    color={tab === i ? "primary" : "default"}
                    className={css}
                    onClick={() => {
                      setTab(i);
                      setSelect(0);
                      setTabCifra(0);
                    }}
                  >
                    {iconData[i]}
                    <Typography>{label}</Typography>
                  </IconButton>
                );
              })}
            </Grid>
            <Grid item xs={12} className={classes.overflow}>
              <Hidden smDown>
                <ListView
                  loading={loading}
                  selectIndex={select}
                  onClickItem={(_, index) => {
                    setSelect(index);
                    setTabCifra(0);
                  }}
                  items={dataLyrics.map((item) => ({
                    icon: <MusicNote />,
                    text: item[lf.LYRIC],
                    title: item[lf.TITLE],
                  }))}
                />
              </Hidden>
              <Hidden mdUp>
                <FormControl fullWidth variant="outlined">
                  <Select
                    value={select}
                    className={classes.select}
                    onChange={({ target }) => {
                      setSelect(target.value);
                    }}
                    MenuProps={{
                      style: {
                        maxHeight: 500,
                      },
                    }}
                  >
                    {dataLyrics.map((item, i) => (
                      <MenuItem key={`lrc-${i + 1}`} value={i}>
                        {item[lf.TITLE]}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Hidden>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6} className={classes.menu}>
            <TabPanel
              selectIndex={tabCifra}
              onChangeTab={(tabIndex) => {
                setTabCifra(tabIndex);
                if (tabIndex > 0) {
                  getCifraByPath(tabIndex - 1);
                }
              }}
              tabs={[
                {
                  label: "Letra",
                  icon: <img src={LyricIcon} alt="lyric" height={30} />,
                  content: lyric && (
                    <Grid item xs={12} className={classes.overflow}>
                      <Typography align="center" variant="h5">
                        {capitalizePharse(lyric?.[lf.TITLE])}
                      </Typography>

                      {paragraphs.map((pharse, j) => (
                        <Typography key={`p-${j}`} className={classes.card}>
                          {pharse.toLowerCase()}
                        </Typography>
                      ))}
                    </Grid>
                  ),
                },
                ...cifras.map((_, i) => ({
                  label: "Cifra " + (i + 1),
                  icon: <img src={GuittarIcon} alt="guittar" height={30} />,
                  content: (
                    <Grid item xs={12} className={classes.overflow}>
                      <Typography align="center" variant="h5">
                        {capitalizePharse(lyric?.[lf.TITLE])}
                      </Typography>
                      <Grid item xs container justifyContent="center">
                        <BannerAds />
                      </Grid>
                      <pre
                        dangerouslySetInnerHTML={{
                          __html: cifras[tabCifra - 1]?.content,
                        }}
                      ></pre>
                    </Grid>
                  ),
                })),
              ]}
            />
          </Grid>
        </Grid>

        <Grid item xs={12} container className={classes.videos}>
          <Typography variant="h6">Relacionados</Typography>
          <div className={classes.scroll}>
            <BannerAds opts={banner320x300} />
            {videosMap?.videos.map((video, i) => {
              return (
                <Box marginLeft={1} key={`vdo-${i + 1}`}>
                  <ReactPlayer
                    width={300}
                    height={250}
                    url={`https://www.youtube.com/watch?v=${video?.videoId}`}
                  />
                </Box>
              );
            })}
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
}
