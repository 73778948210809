import { Chip, Grid } from "@material-ui/core";
import clsx from "clsx";
import booksJson from "../../assets/json/books.json";
import { BIBLE_FIELDS as bf } from "../../commons/fields";
import { useStyles } from "./styles";

export const TagBooks = ({ selectedBook, onClikBook }) => {
  const classes = useStyles();
  const book = selectedBook || booksJson[0];

  return (
    <Grid item xs={12} container justifyContent="center">
      {booksJson.map((item) => {
        const active = book?.[bf.ABREV] === item[bf.ABREV];
        const variant = active ? "default" : "outlined";
        const color = active ? "primary" : "default";
        const css = active && classes.active;

        return (
          <Chip
            clickable
            key={item.book}
            variant={variant}
            color={color}
            className={clsx(classes.book, css)}
            label={item.book}
            onClick={() => {
              onClikBook(item);
            }}
          />
        );
      })}
    </Grid>
  );
};
