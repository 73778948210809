import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  search: null,
  message: null,
  loading: false,
};

export const bibleSlice = createSlice({
  name: "bible",
  initialState,
  reducers: {
    addBookBible: (state, { payload }) => {
      const { bible, book } = payload;

      const newData = [...state.data];
      const i = newData.findIndex((f) => f.bible === bible);

      if (i > -1) {
        newData[i].books.push(book);
      } else {
        newData.push({ bible, books: [book] });
      }

      state.data = newData;
      state.loading = false;
    },
    setSearchBible: (state, { payload }) => {
      state.search = payload;
      state.loading = false;
    },
    setLoading: (state, { payload }) => {
      state.loading = payload;
    },
    setMessage: (state, { payload }) => {
      state.message = payload;
    },
  },
});

const { actions, reducer } = bibleSlice;

export const { addBookBible, setSearchBible, setLoading, setMessage } = actions;

export default reducer;
