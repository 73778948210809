export const capitalizePharse = (text) => {
  if (!text) return "";

  const capitalize = text.replace(/[0-9-.]/g, "").toUpperCase();

  //
  //const blacklist = ["a", "e", "o", "da", "de", "do", "por", "para"];
  //text
  // .split(" ")
  // .reduce((acum, t, i) => {
  //   if (t) {
  //     const str =
  //       blacklist.includes(t.toLowerCase()) && i > 0
  //         ? t.toLowerCase()
  //         : t.charAt(0).toUpperCase().concat(t.substr(1).toLowerCase());
  //     acum.push(str);
  //   }
  //   return acum;
  // }, [])
  // .join(" ");

  return capitalize;
};
