export class LocalStorage {
  static KEY_BIBLE = "BIBLE";
  static KEY_LYRICS = "LYRICS";
  static KEY_VIDEOS = "VIDEOS";
  static KEY_VERSOS = "VERSOS";
  static KEY_SOURCES = "SOURCES";
  static KEY_CIFRAS = "CIFRAS";
  static KEY_SEARCHS = "SEARCHS";

  static save(key, value) {
    window.localStorage.setItem(key, JSON.stringify(value));
  }

  static get(key) {
    const data = window.localStorage.getItem(key);
    if (data) return JSON.parse(data);
  }

  static clear() {
    window.localStorage.clear();
  }

  static remove(key) {
    window.localStorage.removeItem(key);
  }
}
