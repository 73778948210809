import React from "react";
import { Grid, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import { Home, ListAlt, LocalLibrary, Search } from "@material-ui/icons";
import { appelStore, googlePlay } from "../../assets/images";
import {
  BIBLIA_PAGE,
  HOME_PAGE,
  LETRAS_PAGE,
  PESQUISA_PAGE,
  TERMOS_PAGE,
} from "../../commons/constants";
import { APP_NAME } from "../../commons/texts";
import { useStyles } from "./styles";

export default function Footer() {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <Grid container className={classes.root}>
      {!isMobile && (
        <Grid item xs={12} container className={classes.menu}>
          <Typography component="a" href={HOME_PAGE}>
            <Home /> Inicio
          </Typography>
          <Typography component="a" href={BIBLIA_PAGE}>
            <LocalLibrary /> Biblia
          </Typography>
          <Typography component="a" href={LETRAS_PAGE}>
            <ListAlt /> Letras
          </Typography>
          <Typography component="a" href={PESQUISA_PAGE}>
            <Search /> Pesquisa
          </Typography>
        </Grid>
      )}
      <Grid item xs={12} container className={classes.images}>
        <a
          href="https://play.google.com/store/apps/details?id=es.esy.alexandrehenrique.hinariobatista"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={googlePlay} alt="googlePlay" />
        </a>
        <a
          href="https://apps.apple.com/app/hin%C3%A1rio-crist%C3%A3o-e-biblia/id1620777834?platform=iphone"
          rel="noopener noreferrer"
          target="_blank"
        >
          <img src={appelStore} alt="appelStore" />
        </a>
      </Grid>
      <Grid
        item
        xs={12}
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Typography variant="body1">{APP_NAME}</Typography>
        <Typography variant="body2">
          copyright © {new Date().getFullYear()} - AY Apps
        </Typography>
        <Typography
          component="a"
          href={TERMOS_PAGE}
          style={{ color: "white", marginTop: 20 }}
        >
          Politica e Privacidade
        </Typography>
      </Grid>
    </Grid>
  );
}
