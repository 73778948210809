import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  sources: null,
  versos: [],
};

export const bibleSlice = createSlice({
  name: "dominio",
  initialState,
  reducers: {
    setLoading: (state, { payload }) => {
      state.loading = payload;
    },
    setSourceData: (state, { payload }) => {
      state.sources = payload;
    },
    setVersosData: (state, { payload }) => {
      state.versos = payload;
      state.loading = false;
    },
  },
});

const { actions, reducer } = bibleSlice;

export const { setSourceData, setVersosData, setLoading } = actions;

export default reducer;
