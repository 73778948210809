import {
  Box,
  Button,
  Collapse,
  Drawer,
  Grid,
  Hidden,
  IconButton,
  List,
  ListItem,
  Typography,
} from "@material-ui/core";
import {
  ExpandLess,
  ExpandMore,
  HomeOutlined,
  ListAlt,
  Menu as MenuIcon,
  OpenInNew,
  SearchOutlined,
} from "@material-ui/icons";
import clsx from "clsx";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { logo } from "../../assets/images";
import {
  APPS,
  BIBLIA_PAGE,
  HOME_PAGE,
  LETRAS_PAGE,
  PESQUISA_PAGE,
} from "../../commons/constants";
import { APP_NAME } from "../../commons/texts";
import BibleService from "../../services/bible";
import { setLoading, setMessage, setSources } from "../../store/slices/sources";
import { useStyles } from "./styles";

var service = new BibleService();

const MENU = [
  {
    label: "Inicio",
    url: HOME_PAGE,
    icon: <HomeOutlined />,
  },
  {
    label: "Letras",
    url: LETRAS_PAGE,
    icon: <ListAlt />,
  },
  {
    label: "Biblia",
    url: BIBLIA_PAGE,
  },
  {
    label: "Pesquisar",
    url: PESQUISA_PAGE,
    icon: <SearchOutlined />,
  },
];

export default function Header() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const { sources } = useSelector((state) => ({
    sources: state.sources.data,
  }));

  const [openDrawer, setOpenDrawer] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const bibles = sources?.bibles ?? [];

  const createDrawerMenu = () => {
    return (
      <List disablePadding>
        <Typography className={classes.divider}>Menu</Typography>

        {MENU.map((menu, i) => {
          if (menu.label === "Biblia") {
            return bibles.length === 0 ? null : (
              <Fragment key="biblia">
                <ListItem
                  className={classes.listItem}
                  onClick={() => {
                    setExpanded((prev) => !prev);
                  }}
                >
                  Bíblias
                  {expanded ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                  <List>
                    {bibles.map((s, i) => (
                      <ListItem
                        key={`bbl-${i}`}
                        className={classes.listItem}
                        onClick={() => {
                          handleDrawerToggle();
                          history.push(menu.url, {
                            source: s,
                          });
                        }}
                      >
                        <span>{s.label}</span>
                      </ListItem>
                    ))}
                  </List>
                </Collapse>
              </Fragment>
            );
          }
          return (
            <ListItem
              key={`men-${i}`}
              className={classes.listItem}
              onClick={() => {
                handleDrawerToggle();
                history.push(menu.url);
              }}
            >
              {menu.label}
              {menu.icon}
            </ListItem>
          );
        })}

        <Typography className={classes.divider}>Mais</Typography>

        {APPS.map((app, j) => (
          <ListItem
            key={`apps-${j + 1}`}
            className={classes.listItem}
            onClick={() => {
              handleDrawerToggle();
              window.open(app.links[0].url, "__blank");
            }}
          >
            {app.name}
            <OpenInNew />
          </ListItem>
        ))}
      </List>
    );
  };

  const createBarMenu = () => {
    return MENU.map((menu, i) => {
      let css = classes.link;

      if (history?.location?.pathname === menu.url) {
        css = clsx(classes.link, classes.active);
      }

      return (
        <Button
          href={menu.url}
          key={`mnu-${i}`}
          className={css}
          onClick={() => {
            history.push(menu.url);
          }}
        >
          {menu.label}
        </Button>
      );
    });
  };

  const handleDrawerToggle = () => {
    setOpenDrawer(!openDrawer);
  };

  const getSources = useCallback(() => {
    if (!sources) {
      dispatch(setLoading(true));

      service
        .getSources()
        .then(({ result }) => {
          dispatch(setSources(result));
        })
        .catch((err) => {
          dispatch(setMessage(err));
        });
    }
  }, [dispatch, sources]);

  useEffect(() => {
    getSources();
  }, [getSources]);

  const container =
    window !== undefined ? () => window.document.body : undefined;

  return (
    <>
      <Grid container elevation={2} className={classes.root}>
        <Grid item xs={12} container className={classes.toolbar}>
          <Grid item xs md={8} container className={classes.title}>
            <img src={logo} alt="Logo" />
            <Typography
              variant="h6"
              onClick={() => {
                history.push(HOME_PAGE);
              }}
            >
              {APP_NAME}
            </Typography>
          </Grid>

          <Hidden smDown>
            <Grid item xs={6} md={4} className={classes.menu}>
              {createBarMenu()}
            </Grid>
          </Hidden>

          <Hidden mdUp>
            <IconButton
              aria-label="more"
              aria-controls="long-menu"
              aria-haspopup="true"
              onClick={handleDrawerToggle}
            >
              <MenuIcon />
            </IconButton>
          </Hidden>
        </Grid>
      </Grid>
      <Drawer
        anchor="right"
        variant="temporary"
        open={openDrawer}
        container={container}
        onClose={handleDrawerToggle}
        classes={{
          paperAnchorRight: classes.drawer,
        }}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: "block", sm: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: 450,
          },
        }}
      >
        <Box width={window.innerWidth / 2} maxWidth={350}>
          {createDrawerMenu()}
        </Box>
      </Drawer>
    </>
  );
}
