import { configureStore } from "@reduxjs/toolkit";

import lyrics from "./slices/lyrics";
import bible from "./slices/bible";
import dominio from "./slices/dominio";
import sources from "./slices/sources";

export const store = configureStore({
  devTools: process.env.NODE_ENV !== "production",
  reducer: {
    dominio,
    lyrics,
    bible,
    sources,
  },
});
