import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  item: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "15px 10px 15px 5px",
    cursor: "pointer",
    fontSize: 14,

    borderBottom: "1px solid #F3F3F3",
    borderLeft: "1px solid #F3F3F3",
    borderTop: "1px solid #F3F3F3",

    "&:hover": {
      backgroundColor: "#F4F4F4",
    },
  },

  leading: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",

    "& span": {
      marginLeft: 20,
    },
  },

  select: {
    backgroundColor: theme.palette.primary.main,
    color: "#FFF",
    fontWeight: 600,

    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },

  loading: {
    width: "100%",
    height: "80vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));
