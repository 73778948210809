import BiblePage from "../pages/Bible";
import HomePage from "../pages/Home";
import LyricsPage from "../pages/Lyrics";
import SearchPage from "../pages/Search";

import {
  HOME_PAGE,
  LETRAS_PAGE,
  BIBLIA_PAGE,
  PESQUISA_PAGE,
  TERMOS_PAGE,
} from "../commons/constants";
import { TermosPage } from "../pages/Termos";

const routes = [
  {
    path: HOME_PAGE,
    exact: true,
    component: HomePage,
  },
  {
    path: LETRAS_PAGE,
    exact: true,
    component: LyricsPage,
  },
  {
    path: BIBLIA_PAGE,
    exact: true,
    component: BiblePage,
  },
  {
    path: PESQUISA_PAGE,
    exact: true,
    component: SearchPage,
  },
  {
    path: TERMOS_PAGE,
    exact: true,
    component: TermosPage,
  },
];

export default routes;
