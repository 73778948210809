import { IconButton, ThemeProvider } from "@material-ui/core";
import { ExpandLess } from "@material-ui/icons";
import { createBrowserHistory } from "history";
import { useEffect, useState } from "react";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import Footer from "./components/Footer";
import Header from "./components/Header";
import GTM from "./configs/gtm";
import AppRoutes from "./routes";
import { store } from "./store";
import AppTheme, { appColors } from "./styles/theme";

const history = createBrowserHistory();

export default function App() {
  useEffect(() => {
    GTM.init();
  }, []);

  return (
    <Provider store={store}>
      <Router history={history}>
        <ThemeProvider theme={AppTheme}>
          <Header />
          <AppRoutes />
          <Footer />
          <FloatButton />
        </ThemeProvider>
      </Router>
    </Provider>
  );
}

const FloatButton = () => {
  const [position, setPosition] = useState(0);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    setPosition(scrollPosition);
  };

  useEffect(() => {
    handleScroll();
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  if (position < 300) return <></>;

  return (
    <IconButton
      onClick={scrollToTop}
      style={{
        right: 40,
        bottom: 20,
        fontSize: 20,
        marginBottom: 20,
        position: "fixed",
        boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
        backgroundColor: appColors.primaryColor,
        color: appColors.whiteColor,

        "&:hover": {
          backgroundColor: appColors.primaryColor,
          color: appColors.whiteColor,
        },
      }}
    >
      <ExpandLess />
    </IconButton>
  );
};
