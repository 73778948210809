import { ptBR } from "@material-ui/core/locale";
import { createTheme } from "@material-ui/core";

export const appColors = {
  primaryColor: "#00C2CB",
  primaryColorDark: "#237185",
  secondaryColor: "#00C2CB",
  whiteColor: "#FFF",
  blackColor: "#000",
  grayColor: "#C4C4C4",
};

const AppTheme = createTheme(
  {
    appColors,
    palette: {
      primary: {
        main: appColors.primaryColor,
        dark: appColors.primaryColorDark,
      },
      secondary: {
        main: appColors.secondaryColor,
      },
    },
    typography: {
      subtitle1: {
        fontSize: 14,
        fontWeight: 600,
      },
      unica: {},
    },
  },
  ptBR
);

export default AppTheme;
